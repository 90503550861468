<template>
    <!-- WARNING this file is generated edits made will be lost on next generation -->
    <div>
        <NavBar></NavBar>
        <div class="container-fluid">
            <b-row>
            <!-- Sidebar -->
            <TOCChapter chapter-id="Chap33ApplicationConfiguration"></TOCChapter>
				<b-col ></b-col>
                <!-- Main Content -->
                <b-col role="main" md="6" >
					<ChapterHeading chapter-title="Chapter 33: Application Configuration" image-name="config.jpg" image-alt="Application Configuration"></ChapterHeading>
                    <!-- Contents BEGIN -->
                    <div id="what-will-you-learn-in-this-chapter" class="anchor"></div>
<h1 data-number="1"><span class="header-section-number">1</span> What will you learn in this chapter? <a href="#what-will-you-learn-in-this-chapter"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>How to configure an application?</p></li>
<li><p>How to add a command-line option to a program?</p></li>
<li><p>How to create and parse environment variables?</p></li>
</ul>
<div id="technical-concepts-covered" class="anchor"></div>
<h1 data-number="2"><span class="header-section-number">2</span> Technical concepts covered <a href="#technical-concepts-covered"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>Environment variable</p></li>
<li><p>Unmarshal</p></li>
<li><p>Command-line options</p></li>
</ul>
<div id="introduction" class="anchor"></div>
<h1 data-number="3"><span class="header-section-number">3</span> Introduction <a href="#introduction"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>When we write programs for ourselves, we are (often) writing directly in the source code some important options, like, for instance, the port on which our server will listen, the name of the database we are using... But what if you attempt to share your program with others? There is a significant probability that your users will not use the same database name as you are using in the development phase; they will also want to use another port for their server!</p>
<p>And what about security! Do you want everybody to know your database password? You should not commit credentials in your code.</p>
<p>In this section, we will cover how you can configure your applications.</p>
<div id="what-is-it" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="4"><span class="header-section-number">4</span> What is it? <a href="#what-is-it"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>An application is configurable when it offers users the possibility of control the aspects of execution <b-link class="citation" data-cites="rabkin2011static" href="#rabkin2011static" >[@rabkin2011static]</b-link>. For instance, users can define the port on which a server will listen, the database credentials, the duration of the timeout of HTTP requests...</p>
<div id="how-to-do-it" class="anchor"></div>
<h1 data-number="5"><span class="header-section-number">5</span> How to do it? <a href="#how-to-do-it"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>In many open-source applications, the configuration is handled via key-value data structure <b-link class="citation" data-cites="rabkin2011static" href="#rabkin2011static" >[@rabkin2011static]</b-link>. Options are often denoted by a unique name. Unix systems use strings as option names. Windows use a tree structure.</p>
<p>In a real-world application, you often find a class (or a type struct) that exposes the configuration options. This class often parses a file to attribute values to each option. Applications often propose command-line options to adjust configurations.</p>
<div id="command-line-options" class="anchor"></div>
<h1 data-number="6"><span class="header-section-number">6</span> Command-line options <a href="#command-line-options"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>To define a named command-line option, we can use the standard package <span v-highlightjs><code class="go" v-pre style="display: inline">flag</code></span>.</p>
<p>Let’s take an example. You are building a REST API. Your application will expose a web server; you want the listening port to be configurable by the user.</p>
<p>The first step is to define the new flag :</p>
<pre v-highlightjs><code class="go" v-pre >// configuration/cli/main.go 

port := flag.Int(&quot;port&quot;, 4242, &quot;the port on which the server will listen&quot;)</code></pre>
<p>The first argument is the flag name; the second one is the default value; the third is the help text. The returned variable will be a pointer to an integer.</p>
<p>There is an alternative syntax available. You first define a variable, then you pass a pointer to that variable as argument of the <span v-highlightjs><code class="go" v-pre style="display: inline">IntVar</code></span> function :</p>
<pre v-highlightjs><code class="go" v-pre >var port int
flag.IntVar(&amp;port, &quot;port&quot;, 4242, &quot;the port on which the server will listen&quot;)</code></pre>
<p>The next step is to parse the flags :</p>
<pre v-highlightjs><code class="go" v-pre >flag.Parse()</code></pre>
<p>The function <span v-highlightjs><code class="go" v-pre style="display: inline">Parse</code></span> has to be called <strong>before</strong> using the variables that you defined. Internally it will iterate over all the arguments given in the command line and assign values to the flags you have defined.</p>
<p>In the first version (<span v-highlightjs><code class="go" v-pre style="display: inline">flag.Int</code></span>) the variable will be a pointer to an integer (<span v-highlightjs><code class="go" v-pre style="display: inline">*int</code></span>). In the second version (<span v-highlightjs><code class="go" v-pre style="display: inline">flag.Intvar</code></span>) will be of type integer (<span v-highlightjs><code class="go" v-pre style="display: inline">int</code></span>). This specificity changes the way you use the variable afterward:</p>
<pre v-highlightjs><code class="go" v-pre >// version 1 : Int

port := flag.Int(&quot;port&quot;, 4242, &quot;the port on which the server will listen&quot;)
flag.Parse()
fmt.Printf(&quot;%d&quot;,*port)

// version 2 : IntVar

var port2 int   flag.IntVar(&amp;port2, &quot;port2&quot;, 4242, &quot;the port on which the server will listen&quot;) flag.Parse()
fmt.Printf(&quot;%d\n&quot;,port2)</code></pre>
<div id="other-types" class="anchor"></div>
<h3 data-number="6.0.1"><span class="header-section-number">6.0.1</span> Other types <a href="#other-types"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>The <span v-highlightjs><code class="go" v-pre style="display: inline">flag</code></span> package expose functions to add flags that have different types. Here is a list of the types on the left and the flag package’s corresponding functions.</p>
<div class="list">
<p>Int64, Int64Var</p>
<p>String, StringVar</p>
<p>Uint, UintVar</p>
<p>Uint64, Uint64Var</p>
<p>Float64, Float64Var</p>
<p>Duration, DurationVar</p>
<p>Bool, BoolVar</p>
</div>
<div id="usage-in-the-command-line" class="anchor"></div>
<h3 data-number="6.0.2"><span class="header-section-number">6.0.2</span> Usage in the command line <a href="#usage-in-the-command-line"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>To pass the specified flags to your program you have two options :</p>
<pre v-highlightjs><code class="go" v-pre >$ myCompiledGoProgram -port=4242</code></pre>
<p>or</p>
<pre v-highlightjs><code class="go" v-pre >$ myCompiledGoProgram -port 4242</code></pre>
<p>This last usage is forbidden for boolean flags. Instead, the user of your application can set the flag to true by simply writing :</p>
<pre v-highlightjs><code class="go" v-pre >$ myCompiledGoProgram -myBoolFlag</code></pre>
<p>By doing so, the variable behind the flag will be set to true.</p>
<p>To get a view of all flags available, you can use the help flag (which is added by default to your program !)</p>
<pre v-highlightjs><code class="go" v-pre >$ ./myCompiledGoProgram -help
Usage of ./myCompiledProgram:
  -myBool
        a test boolean
  -port int
        the port on which the server will listen (default 4242)
  -port2 int
        the port on which the server will listen (default 4242)</code></pre>
<div id="environment-variables" class="anchor"></div>
<h1 data-number="7"><span class="header-section-number">7</span> Environment variables <a href="#environment-variables"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Some cloud services support configuration via environment variables. Environment variables are easy to set with the export command. To create an environment variable named MYVAR, just type the following command in your terminal<a href="#fn1" class="footnote-ref" id="fnref1" role="doc-noteref"><sup>1</sup></a> :</p>
<pre v-highlightjs><code class="go" v-pre >$ export MYVAR=value</code></pre>
<p>If you want to pass environment variables to your application, use the following syntax</p>
<pre v-highlightjs><code class="go" v-pre >$ export MYVAR=test &amp;&amp; export MYVAR2=test2 &amp;&amp; ./myCompiledProgram</code></pre>
<p>Here we are setting two environment variables, and then we launch the program <span v-highlightjs><code class="go" v-pre style="display: inline">myCompiledProgram</code></span><strong>.</strong></p>
<div id="how-to-get-an-environment-variable-value" class="anchor"></div>
<h3 data-number="7.0.1"><span class="header-section-number">7.0.1</span> How to get an environment variable value <a href="#how-to-get-an-environment-variable-value"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>To retrieve the value of an environment variable, you can use the <span v-highlightjs><code class="go" v-pre style="display: inline">os</code></span> standard package. The package exposes a function named <span v-highlightjs><code class="go" v-pre style="display: inline">Getenv</code></span>. This function takes the name of the variable as argument and returns its value (as a string) :</p>
<pre v-highlightjs><code class="go" v-pre >// configuration/env/main.go 
myvar := os.Getenv(&quot;MYVAR&quot;)
myvar2 := os.Getenv(&quot;MYVAR2&quot;)
fmt.Printf(&quot;myvar : &#39;%s&#39;\n&quot;, myvar)
fmt.Printf(&quot;myvar2 :&#39;%s&#39;\n&quot;, myvar2)</code></pre>
<p>Another method allows you to retrieve environment variables : <span v-highlightjs><code class="go" v-pre style="display: inline">LookupEnv</code></span>. It’s better than the previous one because it will inform you if the variable is not present :</p>
<pre v-highlightjs><code class="go" v-pre >// configuration/env/main.go 
port, found := os.LookupEnv(&quot;DB_PORT&quot;)
if !found {
    log.Fatal(&quot;impossible to start up, DB_PORT env var is mandatory&quot;)
}
portParsed, err := strconv.ParseUint(port, 10, 8)
if err != nil {
    log.Fatalf(&quot;impossible to parse db port: %s&quot;, err)
}
log.Println(portParsed)</code></pre>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">LookupEnv</code></span> will check if the environment variable exists</p>
<ul>
<li><p>If it does not exists, the second result (<span v-highlightjs><code class="go" v-pre style="display: inline">found</code></span>) will be equal to <span v-highlightjs><code class="go" v-pre style="display: inline">false</code></span></p></li>
<li><p>In the previous example, we parse the port retrieved into an uint16 (base 10) with <span v-highlightjs><code class="go" v-pre style="display: inline">strconv.ParseUint</code></span></p></li>
</ul>
<div id="how-to-get-all-environment-variables" class="anchor"></div>
<h3 data-number="7.0.2"><span class="header-section-number">7.0.2</span> How to get ALL environment variables <a href="#how-to-get-all-environment-variables"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>You can also get all the environment variables with the function Environ(), which will return a slice of strings :</p>
<pre v-highlightjs><code class="go" v-pre >fmt.Println(os.Environ())</code></pre>
<p>The returned slice is composed of all the variables in the format “key=value”. Each element of the slice is a key-value pair. The package does not isolate the value from the key. You have to parse the slice values.</p>
<div id="how-to-set-an-environment-variable" class="anchor"></div>
<h3 data-number="7.0.3"><span class="header-section-number">7.0.3</span> How to set an environment variable <a href="#how-to-set-an-environment-variable"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>The os package exposes the <span v-highlightjs><code class="go" v-pre style="display: inline">os.Setenv</code></span> function :</p>
<pre v-highlightjs><code class="go" v-pre >err := os.Setenv(&quot;MYVAR3&quot;,&quot;test3&quot;)
if err != nil {
    panic(err)
}</code></pre>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">os.Setenv</code></span> takes two arguments :</p>
<ul>
<li><p>the name of the variable to set</p></li>
<li><p>its value.</p></li>
</ul>
<p>Note that this can generate an error. You have to handle that error.</p>
<div id="file-based-configuration" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="8"><span class="header-section-number">8</span> File-based configuration <a href="#file-based-configuration"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Configuration can also be saved in a file and parsed automatically by the application.</p>
<p>The format of the file depends on the needs of the application. If you need a complex configuration with levels and hierarchy (in the fashion of the Windows Registry), then you might need to think about formats like JSON, YAML, or XML.</p>
<p>Let’s take the example of a JSON configuration file :</p>
<pre v-highlightjs><code class="go" v-pre >{
    &quot;server&quot;: {
        &quot;host&quot;: &quot;localhost&quot;,
        &quot;port&quot;: 80
    },
    &quot;database&quot;: {
        &quot;host&quot;: &quot;localhost&quot;,
        &quot;username&quot;: &quot;myUsername&quot;,
        &quot;password&quot;: &quot;abcdefgh&quot;
    }
}</code></pre>
<p>We will place this file somewhere in the machine that hosts the application. We have to parse this file inside the application to get those configuration values.</p>
<p>To parse it, we first have to create a type struct in our program :</p>
<pre v-highlightjs><code class="go" v-pre >// configuration/file/main.go 
type Configuration struct {
    Server   Server `json:&quot;server&quot;`
    Database DB                  `json:&quot;database&quot;`
}
type Server struct {
    Host string `json:&quot;host&quot;`
    Port int    `json:&quot;port&quot;`
}
type DB struct {
    Host     string `json:&quot;host&quot;`
    Username string `json:&quot;username&quot;`
    Password string `json:&quot;password&quot;`
}</code></pre>
<p>We create three types struct :</p>
<ul>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">Configuration</code></span> that will group the two other types :</p></li>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">Server</code></span> that holds the properties of the JSON object <strong>server</strong></p></li>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">DB</code></span> that store the properties of the JSON object <strong>database</strong></p></li>
</ul>
<p>Those type structs will allow you to unmarshal the JSON configuration file. The next step is to open the file and read its content :</p>
<pre v-highlightjs><code class="go" v-pre >// configuration/file/main.go 

confFile, err := os.Open(&quot;myConf.json&quot;)
if err != nil {
    panic(err)
}
defer confFile.Close()
conf, err := ioutil.ReadAll(confFile)
if err != nil {
    panic(err)
}</code></pre>
<p>Here we are calling <span v-highlightjs><code class="go" v-pre style="display: inline">os.Open</code></span> to open the file <span v-highlightjs><code class="go" v-pre style="display: inline">"myConf.json"</code></span> located for the example in the same directory as the executable. In real life, you should upload this file to an appropriate location on the machine.</p>
<p>We get the whole content of the file by using <span v-highlightjs><code class="go" v-pre style="display: inline">ioutil.ReadAll</code></span>. This function returns a slice of bytes. The next step is to use this slice of raw bytes with <span v-highlightjs><code class="go" v-pre style="display: inline">json.Unmarshal</code></span> :</p>
<pre v-highlightjs><code class="go" v-pre >// configuration/file/main.go 

myConf := Configuration{}
err = json.Unmarshal(conf, &amp;myConf)
if err != nil {
    panic(err)
}
fmt.Printf(&quot;%+v&quot;,myConf)</code></pre>
<p>We create a variable <span v-highlightjs><code class="go" v-pre style="display: inline">myConf</code></span> that is initialized with an empty struct <span v-highlightjs><code class="go" v-pre style="display: inline">Configuration</code></span><strong>.</strong>Then we pass to <span v-highlightjs><code class="go" v-pre style="display: inline">json.Unmarshal</code></span> the variable conf that contains the slice of bytes extracted from the file, and (as the second argument) a pointer to <span v-highlightjs><code class="go" v-pre style="display: inline">myConf</code></span><strong>.</strong></p>
<p>In the end, our program outputs the following :</p>
<pre v-highlightjs><code class="go" v-pre >{Server:{Host:localhost Port:80} Database:{Host:localhost Username:myUsername Password:abcdefgh}}</code></pre>
<p>You can then share this variable with the whole application.</p>
<div id="the-github.comspf13viper-module" class="anchor"></div>
<h1 data-number="9"><span class="header-section-number">9</span> The github.com/spf13/viper module <a href="#the-github.comspf13viper-module"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>The module <span v-highlightjs><code class="go" v-pre style="display: inline">github.com/spf13/viper</code></span> is at the time of writing very popular among the Go community. It has more than 14.000 stars and more than 1.3k forks<a href="#fn2" class="footnote-ref" id="fnref2" role="doc-noteref"><sup>2</sup></a>.</p>
<p>This package allows you to simply define a configuration for your application from files, environment variables, command line, buffers... It also supports an interesting feature: if your configuration changes during your application’s lifetime, it will be reloaded.</p>
<div id="read-from-a-file" class="anchor"></div>
<h2 data-number="9.1"><span class="header-section-number">9.1</span> Read from a file <a href="#read-from-a-file"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>Let’s give it a try :</p>
<pre v-highlightjs><code class="go" v-pre >// configuration/viper/main.go 
//...
// import &quot;github.com/spf13/viper&quot;
viper.SetConfigName(&quot;myConf&quot;)
viper.(&quot;.&quot;)
err := viper.ReadInConfig()
if err != nil {
    log.Panicf(&quot;Fatal error config file: %s\n&quot;, err)
}</code></pre>
<p>First, you have to define the name of the config file you want to load :<span v-highlightjs><code class="go" v-pre style="display: inline">viper.SetConfigName("myConf")</code></span>. Here we are loading the file named <span v-highlightjs><code class="go" v-pre style="display: inline">myConf</code></span>. Note that we do not give viper the extension name of the file. It will retrieve the correct encoding and parse the file.</p>
<p>In the next line, we have to tell viper were to search with the function<span v-highlightjs><code class="go" v-pre style="display: inline">AddConfigPath</code></span>. The point means “look in the current directory” for a file named myConf. The supported extension at the time of writing are :</p>
<ul>
<li><p>json,</p></li>
<li><p>toml,</p></li>
<li><p>yaml,</p></li>
<li><p>yml,</p></li>
<li><p>properties,</p></li>
<li><p>props,</p></li>
<li><p>prop,</p></li>
<li><p>hcl</p></li>
</ul>
<p>Note that we could have added another path to search for config. The <span v-highlightjs><code class="go" v-pre style="display: inline">AddConfigPath</code></span> function will append the path to a slice.</p>
<p>When we call <span v-highlightjs><code class="go" v-pre style="display: inline">ReadInConfig</code></span>, the package will look for the file specified.</p>
<p>You can then use the configuration loaded :</p>
<pre v-highlightjs><code class="go" v-pre >fmt.Println(viper.AllKeys())
//[database.username database.password server.host server.port database.host]
fmt.Println(viper.GetString(&quot;database.username&quot;))
// myUsername</code></pre>
<p>With the <span v-highlightjs><code class="go" v-pre style="display: inline">AllKeys</code></span> function, you can retrieve all the existing configuration keys of your application. To read a specific configuration variable, you can use the function <span v-highlightjs><code class="go" v-pre style="display: inline">GetString</code></span>.</p>
<p>The <span v-highlightjs><code class="go" v-pre style="display: inline">GetString</code></span> function takes as parameter the key of the configuration option. The key is a reflection of the configuration file hierarchy. Here <span v-highlightjs><code class="go" v-pre style="display: inline">"database.username"</code></span> means that we access the value of the property <strong>username</strong> from the object <strong>database</strong>.</p>
<p>The database object also has the property host. To access it, the key is simply <span v-highlightjs><code class="go" v-pre style="display: inline">"database.host"</code></span>.</p>
<p>Viper exposes <span v-highlightjs><code class="go" v-pre style="display: inline">GetXXX</code></span> functions for the types <span v-highlightjs><code class="go" v-pre style="display: inline">bool</code></span>, <span v-highlightjs><code class="go" v-pre style="display: inline">float64</code></span>, <span v-highlightjs><code class="go" v-pre style="display: inline">int</code></span> , <span v-highlightjs><code class="go" v-pre style="display: inline">string</code></span> , <span v-highlightjs><code class="go" v-pre style="display: inline">map[string]interface{}</code></span>, <span v-highlightjs><code class="go" v-pre style="display: inline">map[string]string</code></span>, slice of strings, time, and duration. It’s also possible to simply use Get to retrieve a configuration value. The returning type of this last function is the empty interface. I do not recommend it because it can lead to type errors. In our case, we are expecting a string, but if a user puts an integer into the config file, it will return an int. Your program will not work as expected and might panic.</p>
<div id="read-from-environment-variables" class="anchor"></div>
<h2 data-number="9.2"><span class="header-section-number">9.2</span> Read from environment variables <a href="#read-from-environment-variables"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>Viper can parse environment variables automatically with a prefix :</p>
<pre v-highlightjs><code class="go" v-pre >viper.SetEnvPrefix(&quot;myapp&quot;)
viper.AutomaticEnv()</code></pre>
<p>With those two lines, each time, you will call a getter method (<span v-highlightjs><code class="go" v-pre style="display: inline">GetString</code></span>, <span v-highlightjs><code class="go" v-pre style="display: inline">GetBool</code></span>, ...) viper will look for environment variables with the prefix MYAPP_ :</p>
<pre v-highlightjs><code class="go" v-pre >fmt.Println(viper.GetInt(&quot;timeout&quot;))</code></pre>
<p>will look for the value of an environment variable named <span v-highlightjs><code class="go" v-pre style="display: inline">MYAPP_TIMEOUT</code></span>.</p>
<div id="problems-and-how-to-avoid-them" class="anchor"></div>
<h1 data-number="10"><span class="header-section-number">10</span> Problems and how to avoid them <a href="#problems-and-how-to-avoid-them"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="missing-documentation" class="anchor"></div>
<h2 data-number="10.1"><span class="header-section-number">10.1</span> Missing documentation <a href="#missing-documentation"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>Many projects are suffering from a lack of documentation concerning their configuration options.</p>
<p>By documenting every single option available in a separate file, you are lowering your software’s adoption barrier.</p>
<p>Inside a company, you might not be the person who will handle your application’s deployment. Hence you have to include in your estimates the documentation time. Better documentation means a reduced time to market for your solution.</p>
<p>The interesting study of Ariel Rabkin and Randy Katz from the Berkeley University <b-link class="citation" data-cites="rabkin2011static" href="#rabkin2011static" >[@rabkin2011static]</b-link> demonstrated that even for large open-source projects (they studied seven large projects like Cassandra, Hadoop, HBase...) the documentation of configuration was inaccurate :</p>
<ul>
<li><p>There are mentions of configuration options that do not exist in the application’s source code. They noted that sometimes the options are simply commented into the application source code.</p></li>
<li><p>Some options exist in the application source code that are not even documented.</p></li>
</ul>
<p>The solution is easy: create precise documentation of your configuration options and keep it up to date when the project evolves.</p>
<div id="configuration-mistakes" class="anchor"></div>
<h2 data-number="10.2"><span class="header-section-number">10.2</span> Configuration mistakes <a href="#configuration-mistakes"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>During a study <b-link class="citation" data-cites="nagaraja2004understanding" href="#nagaraja2004understanding" >[@nagaraja2004understanding]</b-link> technical operators were asked to perform operations on a realistic internet system (three-tier auction application). It has been observed that 50% of errors were configuration errors!</p>
<p>This figure is impressive and also very interesting. Systems can fail because of configuration mistakes. You cannot protect yourself against users that put the wrong port number in the configuration. But your application can protect itself from wrong configuration.</p>
<ul>
<li><p>By detecting it and alerting the user.</p></li>
<li><p>By not replacing a fraudulent value with the default one.</p></li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >// configuration/error-detection/main.go 
//...
dbPortRaw := os.Getenv(&quot;DATABASE_PORT&quot;)
dbPort, err := strconv.ParseUint(port, 10, 16)
if err != nil {
    log.Panicf(&quot;Impossible to parse database port number &#39;%s&#39;. Please double check the env variable DATABASE_PORT&quot;,dbPortRaw)
}</code></pre>
<p>In the previous code listing, we are retrieving the value of the environment variable <span v-highlightjs><code class="go" v-pre style="display: inline">DATABASE_PORT</code></span>. We then attempt to convert it to an <span v-highlightjs><code class="go" v-pre style="display: inline">uint16</code></span>. If an error occurs, we refuse to start the application and inform the user about what’s wrong.</p>
<p>Note here that we tell the user what to do to fix this error. This is a good practice, it only takes you 10 seconds to write, but it might save 1 hour of research to your user.</p>
<p>Double-check also that the program effectively uses all your configuration variables.</p>
<div id="security" class="anchor"></div>
<h1 data-number="11"><span class="header-section-number">11</span> Security <a href="#security"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Configuration variables are often composed of secrets: passwords, access tokens, encryption keys... A potential leak of those secrets may cause harm. None of the solutions above are perfect to store and secure production secrets. Rotation of those secrets is also not easy with the solution evoked before.</p>
<p>Some open-source solutions exist to handle this very specific issue. They offer a large set of features to protect, monitor, and audit the usage of your secrets. At the time of writing, it seems that Vault, edited by Hashicorp, is the most popular.<a href="#fn3" class="footnote-ref" id="fnref3" role="doc-noteref"><sup>3</sup></a> And it’s written in Go!</p>
<div id="test-yourself" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="12"><span class="header-section-number">12</span> Test yourself <a href="#test-yourself"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="questions" class="anchor"></div>
<h2 data-number="12.1"><span class="header-section-number">12.1</span> Questions <a href="#questions"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ol type="1">
<li><p>How to add a command-line option of type string to a program?</p></li>
<li><p>How to check if an environment variable exists &amp; get its value in the same call?</p></li>
<li><p>How to set an environment variable?</p></li>
<li><p>How to configure your application with a file?</p></li>
</ol>
<div id="answers" class="anchor"></div>
<h2 data-number="12.2"><span class="header-section-number">12.2</span> Answers <a href="#answers"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ol type="1">
<li><p>How to add a command-line option of type string to a program?</p>
<ol type="1">
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">var password stringflag.StringVar(&amp;password,"password", "default","the db password")// define other flags// parse input flagsflag.Parse()</code></span></p></li>
<li><p>password2 := flag.String(“password2”,“default”, “the db password”)</p></li>
</ol></li>
<li><p>How to check if an environment variable exists &amp; get its value in the same call?</p>
<ol type="1">
<li><p>With the function <span v-highlightjs><code class="go" v-pre style="display: inline">LookupEnv</code></span> from the package <span v-highlightjs><code class="go" v-pre style="display: inline">os</code></span></p></li>
<li><p>port, ok := os.LookupEnv(“DB_PORT”) if !ok { log.Fatal(“impossible to start up, DB_PORT env var is mandatory”) }</p></li>
</ol></li>
<li><p>How to set an environment variable?</p>
<ol type="1">
<li><p>With the function <span v-highlightjs><code class="go" v-pre style="display: inline">SetEnv</code></span> from the package <span v-highlightjs><code class="go" v-pre style="display: inline">os</code></span></p></li>
<li><p>err := os.Setenv(“MYVAR3”, “test3”) if err != nil { panic(err) }</p></li>
</ol></li>
<li><p>How to configure your application with a JSON file?</p>
<ol type="1">
<li><p>Create a specific type with all your config variables.</p></li>
<li><p>At bootstrap, open the JSON file</p></li>
<li><p>Unmarshal the file.</p></li>
</ol></li>
</ol>
<div id="key-takeaways" class="anchor"></div>
<h1 data-number="13"><span class="header-section-number">13</span> Key Takeaways <a href="#key-takeaways"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>Application configuration can be done via command-line options.</p></li>
<li><p>The standard package <span v-highlightjs><code class="go" v-pre style="display: inline">flag</code></span> provide methods to add such options.</p></li>
</ul>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">var password stringflag.StringVar(&amp;password,"password", "default","the db password")// define other flags// parse input flagsflag.Parse()</code></span></p>
<ul>
<li><p>The configuration options are given at program startup via the command line : <span v-highlightjs><code class="go" v-pre style="display: inline">$ myCompiledGoProgram -password insecuredPass</code></span></p></li>
<li><p>The configuration is generally loaded at application startup (in the main)</p></li>
<li><p>Configuration of an application can also be done via environment variables</p></li>
<li><p>To retrieve an environment variable, you can use the <span v-highlightjs><code class="go" v-pre style="display: inline">os</code></span> package</p>
<ul>
<li><p>dbHost := os.GetEnv(“DB_HOST”)</p></li>
<li><p>dbHost, found := os.LookupEnv(“DB_HOST”)</p></li>
</ul></li>
<li><p>You can also handle configuration via a file (JSON, YAML,.…). The idea is to create a type struct &amp; unmarshal the file contents.</p></li>
<li><p>The module github.com/spf13/viper is a popular reference to handle application configuration.</p></li>
<li><p>Configuration options should be documented carefully by developers. An up to date documentation is a competitive advantage.</p></li>
<li><p>A dedicated secret management solution should handle application secrets.</p></li>
</ul>
<section class="footnotes" role="doc-endnotes">
<hr />
<ol>
<li id="fn1" role="doc-endnote"><p>In Unix systems, environment variables are local to the process on which they are defined<a href="#fnref1" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
<li id="fn2" role="doc-endnote"><p>Checked on 02/22/21<a href="#fnref2" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
<li id="fn3" role="doc-endnote"><p>Source: https://github.com/search?o=desc&amp;q=secrets+management&amp;s=stars&amp;type=Repositories last checked 02/22/21<a href="#fnref3" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
</ol>
</section>

                    <!-- END CONTENT -->
                    <!-- Bibliography -->
                    <h1>Bibliography</h1>
                    <ChapterBibliography chapter-id="Chap33ApplicationConfiguration"></ChapterBibliography>
					<!-- Next / Previous -->
					<b-row class="ml-1 mr-1 ">
						
							<b-col class="text-center border mr-1 p-2" >
								<router-link :to="{name:'Chap32Templates'}">
									<p><u><small>Previous</small></u></p>
									<p><small>Templates</small></p>
								</router-link>
							</b-col>
						
						
							<b-col class="text-center border p-1 ">
								<router-link :to="{name:'Chap34Benchmarks'}">
									<p><u><small>Next</small></u></p>
									<p><small>Benchmarks</small></p>
								</router-link>
							</b-col>
						
					</b-row>
					<b-row class="mt-1 ml-1 mr-1">
						<b-col class="text-center border p-1 ">
							<b-link :to="{name:'Home'}" >Table of contents</b-link>
						</b-col>
					</b-row>
          			<FeedbackInvite></FeedbackInvite>
					<NewsletterInput></NewsletterInput>
					<Footer></Footer>
                </b-col>
				<b-col ></b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import TOCChapter from "@/components/toc/TocChapter";
import ChapterBibliography from "@/components/ChapterBibliography";
import NavBar from "@/components/NavBar";
import { BIconLink45deg } from 'bootstrap-vue'
import Footer from "@/components/Footer";
import ChapterHeading from "@/components/ChapterHeading";
import BuyCopyInvite from "@/components/BuyCopyInvite";
import NewsletterInput from "@/components/NewsletterInput";
import FeedbackInvite from "@/components/FeedbackInvite";

const title = "Application Configuration - Practical Go Lessons"
const description = "An application is configurable when it offers users the possibility of control the aspects of execution. For instance, users can define the port on which a server will listen, the database credentials, the duration of the timeout of HTTP requests... How to configure a Go application. How to use viper."

export default {
  name: "Chap33ApplicationConfiguration",
  components: {FeedbackInvite,BuyCopyInvite,NewsletterInput,ChapterHeading, ChapterBibliography,TOCChapter,NavBar,BIconLink45deg, Footer},

  created() {
    window.scrollTo(0,0);
  },data () {return {publicPath: process.env.BASE_URL}},
  metaInfo: {
      title: title,
      htmlAttrs: {
        lang: 'en',
      },
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: description },
        { name: 'robots', content: "index, follow" },
        { property: 'og:locale', content: process.env.VUE_APP_SITE_LOCALE_META },
        { property: 'og:type', content: "website" },
        { property: 'og:title', content: title},
        { property: 'og:description', content: description },
        { property: 'og:url', content: window.location.href },
        { property: 'og:site_name', content: 'Practical Go Lessons' },
        { property: 'twitter:card', content: "summary_large_image" },
        { property: 'twitter:creator', content: process.env.VUE_APP_TWITTER_USERNAME }
      ],
      link : [
        { rel : "canonical", href : window.location.href}
      ]

    }
}
</script>

<style scoped>

</style>
